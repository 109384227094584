import React, { useEffect, useState } from "react";
import {
  Button,
  Form, Spinner,
} from "react-bootstrap";
import { auth } from "../config/firebase";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, useNavigate } from "react-router-dom";
import { ApiRequests } from "../service/ApiRequests";
import ForgetPassword from "../components/ForgetPassword";
import { GoogleAuthProvider, OAuthProvider, signInWithPopup } from "firebase/auth";
import { countUserEvent } from "../utils/utlis";
import FooterLogin from "../components/FooterLogin";

AOS.init();
export default function SignIn() {
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState()
  const [forget, setForget] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState("");
  console.log("error1111111111", error)
  const [error2, setError2] = useState("");
  console.log("error2:::::", error2)
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    setValidated(true);
    setLoading(true);
    try {
      await auth.signInWithEmailAndPassword(user.email, data.password);
      // navigate('/');
      // navigate("/signup/organization");
      navigate("/organizations");
      console.log("User logged in successfully!");
    } catch (error) {
      setError(error.message);
      if (error?.response?.data) {
        setError(error?.response?.data);
      }
      console.log("error?.code", error?.code)
      if (error?.code === "auth/invalid-credential") {
        setError2("Wrong password. Try again or click Forgot password.");
      } if (error?.code === "auth/network-request-failed") {
        setError2("Network fail");
      } if (error?.code === "auth/too-many-requests") {
        setError2("Wrong password. Try again or click Forgot password.");
      } if (error?.code === "ERR_BAD_REQUEST") {
        setError2("User not found");
      }
      console.log("test err", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUser = async (event) => {
    event.preventDefault()
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    setValidated(true);
    setLoading(true);
    try {
      const { data: user } = await ApiRequests.getUserWithEmail(data.email);
      console.log(user)
      setUser(user)
    } catch (error) {
      // setError(error.message);
      if (error?.response?.data) {
        setError(error?.response?.data);
      }
      console.log("error?.code", error?.code)
      // if (error?.code === "auth/invalid-credential") {
      //   setError2("Wrong password. Try again or click Forgot password.");
      // } if (error?.code === "auth/network-request-failed") {
      //   setError2("Network fail");
      // } if (error?.code === "auth/too-many-requests") {
      //   setError2("Account has been temporarily disabled due to many failed login");
      // } if (error?.code === "ERR_BAD_REQUEST") {
      //   setError2("User not found");
      // }
      console.log("test err", error);
    } finally {
      setLoading(false);
    }

  }

  const googleProvider = new GoogleAuthProvider();
  const appleProvider = new OAuthProvider('apple.com')
  const microsoftProvider = new OAuthProvider('microsoft.com');
  // const auth = getAuth();
  const signIn = (provider) => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        const user = result.user
        console.log("Signin", user)
        // console.log("Google Signup", token)
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        // const credential = GoogleAuthProvider.credentialFromError(error);
        console.log("Signin", error)
        console.log("Signin", errorCode)
        console.log("Signin", errorMessage)
        console.log("Signin", email)
        // console.log("Google Signup", credential)

      });
  }
  useEffect(() => {
    if (auth.currentUser) {
      navigate('/organizations');
    }
  }, [navigate, auth.currentUser])


  console.log("error", error)

  return (

    <>
      <div>
        <div>
          <div>
            {user && forget && <ForgetPassword user={user} setForget={setForget} setUser={setUser} setData={setData} data={data} />}
            <Form noValidate validated={validated} onSubmit={user ? handleSubmit : fetchUser}>
              {user && !forget ?
                <div className='accounts-body'>
                  <div className="accounts-logo">
                    <Link to='/'>
                      <img
                        src={require("../images/logo/logo.png")} alt="logo"
                      />
                    </Link>
                    {(location.pathname === '/signup') ?
                      <Link to="/signin">Login</Link>
                      :
                      <Link to="/signup">Sign Up</Link>
                    }
                  </div>
                  <div className='create-account-card'>
                    <h1>Welcome Back!</h1>
                    <div className='form-card'>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='user-card'>
                            <div className='profile'>
                              <img className='user' src={require('../images/AVATARS/Flare.png')} alt="Flare" />
                              <span>
                                {user?.displayName || (
                                  user?.given_name
                                    ?
                                    (
                                      user?.given_name
                                      + ' ' +
                                      user?.family_name
                                    ) :
                                    user?.email
                                )}
                                {
                                  user?.given_name || user?.displayName
                                    ?
                                    <>
                                      <br />
                                      {/* <span className="fs-6 text-muted">{user?.email}</span> */}
                                    </>
                                    :
                                    ""
                                }
                              </span>
                            </div>
                            <Button variant="light" className='edit' onClick={() => { setUser(); setError(null); setError2(null); setData({ ...data, password: "" }); countUserEvent("Sign_In", { eventName: "Sign_In" }) }}>
                              <i className="fa-regular fa-pen"></i>
                            </Button>
                          </div>
                        </div>
                        <div className='col-lg-12'>
                          <div className='input-group'>
                            <Form.Control
                              style={error2 ? { border: "1px solid #EF4444" } : {}}
                              name="password"
                              className="form-control"
                              required
                              value={data?.password}
                              onChange={(e) => {
                                setData({ ...data, password: e.target.value })
                                setError(null);
                                setError2(null);
                              }}
                              type={showPassword ? "text" : "password"}
                              placeholder="Password"
                            />
                            <i className={showPassword ? "fa-light fa-eye" : "fa-light fa-eye fa-eye-slash"} onClick={() => setShowPassword(!showPassword)}></i>
                            {/* <i className="fa-light fa-eye-slash"></i> */}
                          </div>
                        </div>
                        <div className='col-lg-12'>
                          <div className='forgot-pass'>
                            <div className="form-check">
                              {/* <input className="form-check-input" type="checkbox" value="" onChange={(e) => setShowPassword(e.target.checked)} id="flexCheckDefault" />
                              <label className="form-check-label" for="flexCheckDefault">
                                Show password
                              </label> */}
                            </div>
                            <div><a onClick={() => { setForget(true); setError(null); setError2(null); countUserEvent("Forgot_Password", { eventName: "Forgot_Password" }) }}>Forgot password?</a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button disabled={loading} type="submit" className='btn btn-primary'>
                      {loading ? <Spinner /> : "Next"}
                    </button>



                    {/* <div className='help-center mt-5'>
                      <a href='https://docs.ilmiya.com'>Help</a>
                      <span>|</span>
                      <a href='https://ilmiya.com/legal/privacy-policy'>Privacy</a>
                      <span>|</span>
                      <a href='https://ilmiya.com/legal/terms-of-service'>Terms</a>
                    </div> */}

                    {error2 && user && !forget && (
                      <div className="flex-box-two"><i className="fa-solid fa-circle-xmark"></i> <span className="ps-3" style={{ color: "#2D2F31" }}>{error2}</span></div>

                    )}



                    {/* {error &&
                      <div className='wrong-pass-card'>
                        <i className="fa-solid fa-circle-xmark"></i>
                        <span>
                          Wrong password. Try again or click Forgot password.. Try again or click Forgot password.
                        </span>
                      </div>
                    } */}
                  </div>
                </div>
                :
                !forget ?
                  <div className='accounts-body main-accounts-body'>
                 <div className="accounts-logo">
                    <Link to='/'>
                      <img
                        src={require("../images/logo/logo.png")} alt="logo"
                      />
                    </Link>
                    {(location.pathname === '/signup') ?
                      <Link to="/signin">Login</Link>
                      :
                      <Link to="/signup">Sign Up</Link>
                    }
                  </div>
                  {/* <div className="inner-body"> */}
                    <div className='create-account-card mb-4'>
                      <h1>Log In</h1>
                      <div className='form-card'>
                        <div className='row'>
                          <div className='col-lg-12'>
                            <div className='input-group'>
                              {/* <Form.Group controlId="email" className="mb-2"> */}
                              {/* <Form.Label>Email</Form.Label> */}
                              <label htmlFor="username" >Username or Email</label>
                              <Form.Control
                                name="email"
                                required
                                value={data?.email}
                                onChange={(e) => {
                                  setData({ ...data, email: e.target.value });
                                  setError(null);
                                  setError2(null);
                                }}
                                type="text"
                                placeholder="Username or E-mail"
                              />
                              {/* <Form.Control.Feedback type="invalid">
                                Please Enter Email!
                              </Form.Control.Feedback> */}
                              {/* </Form.Group> */}
                              {/* <input type="text" placeholder='Username or Email' className='form-control' /> */}
                            </div>
                            {/*  <div className="create-label">
                              <label htmlFor="pwd">Password</label>
                              <div className="input-group">
                                <Form.Control
                                  name="password"
                                  placeholder="Password"
                                />
                                {showPassword ? (
                                  <i
                                    onClick={() => setShowPassword(!showPassword)}
                                    className="fa-light fa-eye-slash"
                                  ></i>
                                ) : (
                                  <i
                                    onClick={() => setShowPassword(!showPassword)}
                                    className="fa-light fa-eye"
                                  ></i>
                                )}
                              </div>
                            </div>*/}
                          </div>
                        </div>
                      </div>
                      <button disabled={loading} type="submit" className='btn btn-primary'>
                        {loading ? <Spinner /> : "login"}
                      </button>



                      {/* <div className="alert alert-danger mt-3" role="alert" style={{ width: "100%" }} >
                          {"error2"}
                        </div> */}


                      {/* {error && !user && !forget && (
                        <div className="alert alert-danger mt-3" role="alert" style={{ width: "100%" }}>
                          {error?.message}
                        </div>
                      )} */}

                      <div className='devider'>
                        <div className='linee'></div>
                        <span>Or</span>
                      </div>
                      <div className='other-options'>
                        <div className='row'>
                          <div className='col-12'>
                            <div className='option-btn' onClick={() => signIn(googleProvider)}>
                              <img src={require('../images/logo/Google.png')} />
                              <strong>Continue with Google</strong>
                            </div>
                          </div>
                          {/* <div className='col-4'>
                            <div className='option-btn' onClick={() => signIn(appleProvider)}>
                              <img src={require('../images/logo/apple.png')} />
                            </div>
                          </div>
                          <div className='col-4'>
                            <div className='option-btn' onClick={() => signIn(microsoftProvider)}>
                              <img src={require('../images/logo/Microsoft.png')} />
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className='already-have-account'>
                        New to ilmiya?   <Link to='/signup'> Get started <i className="fa-regular fa-arrow-right"></i></Link>
                      </div>
                      <div className='terms-privacy'>
                        By creating an account you agree to the <a href='#'>Terms of Service</a> and <a href='#'>Privacy Policy</a>.
                      </div>

                      {/* <div onClick={() => { countUserEvent('Agree_Terms_Services', { eventName: "Agree_Terms_Services" }) }} className='terms-privacy'>
                        By creating an account you agree to the <br/>
                         <a href='https://ilmiya.com/legal/terms-of-service'>Terms of Service</a> and  
                         <a href='https://ilmiya.com/legal/privacy-policy'> Privacy Policy</a>.
                      </div> */}
                      {/* <div className='help-center'>
                        <a href='https://docs.ilmiya.com'>Help</a>
                        <span>|</span>
                        <a href='https://ilmiya.com/legal/privacy-policy'>Privacy</a>
                        <span>|</span>
                        <a href='https://ilmiya.com/legal/terms-of-service'>Terms</a>
                      </div> */}

                      {error && !user && !forget && (

                        <div className="flex-box-two"><i className="fa-solid fa-circle-xmark"></i> <span className="ps-3" style={{ color: "#2D2F31" }}>{error?.message}</span></div>

                      )}

                    {/* </div> */}
                    {/* </div> */}
                    </div>
                  </div> : null
              }
            </Form>
          </div>
        </div>

      </div>
      <FooterLogin />
    </>
  );
}
